import { Button, ConfigProvider, Form, notification, PageHeader } from "antd";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { resolver } from "../../../Config/serviceConfig";
import { UserType } from "../../../types";
import styles from "./UserCreate.module.scss";
import { UserForm } from "Components/UserForm/UserForm";

export default function UserCreate() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createUserMutation = useMutation((values: UserType) => resolver.createNewUser(values), {
    onSuccess: async (data) => {
      notification.success({
        message: t("createUser.successRequest"),
        description: t("createUser.successRequestDescription"),
        placement: "topRight",
        duration: 2,
      });
      navigate(`/user/edit/${data.id}`);
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: t("createUser.failedRequest"),
        description:
          error.response?.status === 409
            ? t("createUser.unavailableUserOrEmail")
            : t("createUser.failedRequestDescription"),
        placement: "topRight",
      });
    },
  });

  const onFinish = (values: UserType) => {
    createUserMutation.mutate(values);
  };

  return (
    <div className={styles.formContainer}>
      <PageHeader
        className={styles.container}
        title={t("createUser.title")}
        subTitle={t("createUser.subTitle")}
        data-testid="userCreate-pageHeader-container"
        onBack={() => navigate("/users-list")}
      />
      <ConfigProvider
        form={{
          /* eslint-disable no-template-curly-in-string */
          validateMessages: {
            required: "${label} " + t("editUser.labelIsRequired"),
          },
        }}
      >
        <Form form={form} name="control-hooks" onFinish={onFinish} labelCol={{ span: 4 }}>
          <UserForm></UserForm>
          <Form.Item style={{ paddingLeft: "2rem" }} wrapperCol={{ offset: 4, span: 16 }}>
            <Button data-testid="createUser-createButton" type="primary" htmlType="submit">
              {t("app.create")}
            </Button>
            <Button data-testid="createUser-cancelButton" htmlType="button" onClick={() => navigate(`/users-list`)}>
              {t("app.cancel")}
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
}
