import { Button, Col, ConfigProvider, Form, Input, Modal, PageHeader, Row, Select, Switch } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { Fieldset } from "../../Components/Fieldset/Fieldset";
import { businessAdminUrl, resolver } from "../../Config/serviceConfig";
import { ContractPartnerData, EditContractPartnerDataType, PurchaseSection, SupplierData } from "../../types";
import { DefaultOptionType } from "rc-select/lib/Select";

import { availableLanguages, returnsIncluded, settlementTypes } from "./constants";

import styles from "./Editor.module.scss";

const { Option } = Select;

interface EditorProps {
  initialData?: ContractPartnerData;
  isEditing: boolean;
  onSave: UseMutationResult<void, unknown, ContractPartnerData>;
}

const Editor: React.FC<EditorProps> = ({ initialData, isEditing, onSave }) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const purchaseSectionsUrl = businessAdminUrl.getPurchaseSections();
  const suppliersUrl = businessAdminUrl.getContractPartnerSuppliers();

  const elementPrefix = isEditing ? "edit" : "create";

  const { data: purchaseSections, isSuccess: isPurchaseSectionsSuccess } = useQuery<PurchaseSection[]>({
    queryKey: purchaseSectionsUrl,
    queryFn: () => resolver.query(purchaseSectionsUrl),
  });

  const { data: suppliers, isSuccess: isSuppliersSuccess } = useQuery<SupplierData[]>({
    queryKey: suppliersUrl,
    queryFn: () => (initialData?.supplier ? Promise.resolve([initialData?.supplier]) : resolver.query(suppliersUrl)),
  });

  const checkValidity = () => {
    form.validateFields();
  };

  const onFinish = (values: EditContractPartnerDataType) => {
    const newContractPartnerData: ContractPartnerData = {
      id: Number(values.supplier.id),
      onboardedPurchaseSections: values.purchaseSections?.map((purchaseSection) => ({
        id: purchaseSection.value === "*" ? null : purchaseSection.value,
        name: purchaseSection.label,
      })),
      specialSettlementCompanyCode: values.specialSettlementCompanyCode ? values.specialSettlementCompanyCode : null,
      settlementType: values.settlementType ? values.settlementType : null,
      supplier: suppliers?.find((supplier) => values.supplier.id === supplier.id) || values.supplier,
      communication: values.communication,
      isActive: values.isActive,
      hasSpecialPop: values.hasSpecialPop,
      isOnboarded: values.isOnboarded,
      isConditionApprovalBlacklisted: values.isConditionApprovalBlacklisted,
      isPopApprovalBlacklisted: values.isPopApprovalBlacklisted,
      updatedAt: values.updatedAt,
      updatedBy: values.updatedBy,
      createdAt: values.createdAt,
      createdBy: values.createdBy,
      ipDurationDays: values.ipDurationDays,
      popDurationDays: values.popDurationDays,
      customerReturnsIncluded: String(values.customerReturnsIncluded) === "true",
      recalcOptionEnabled: values.recalcOptionEnabled,
      recalcDurationDays: values.recalcDurationDays,
    };
    if (isEditing) {
      newContractPartnerData.version = initialData?.version;
    }
    onSave.mutate(newContractPartnerData);
  };

  const recalcOptionEnabled = Form.useWatch("recalcOptionEnabled", form);

  const handleFormUpdate = () => {
    if (!isFormDirty) {
      // setting true so when clicking back, you will be prompted the modal informing you that you have ongoing changes on the page
      // and not redirecting you to the contract partners list page directly
      setIsFormDirty(true);
    }
  };

  const handleFilter = (input: string, option: DefaultOptionType | undefined) =>
    option ? String(option.children).toLowerCase().includes(input.toLowerCase()) : false;

  const watchSupplier = Form.useWatch(["supplier"], form);

  const selectedSupplier = useMemo(
    () => suppliers?.find((supplier) => supplier.id === watchSupplier?.id),
    [watchSupplier], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (isPurchaseSectionsSuccess && isSuppliersSuccess) {
    return (
      <>
        <div className={styles.formContainer}>
          <PageHeader
            className={styles.container}
            title={t(`contractPartners.${elementPrefix}.title`)}
            subTitle={t(`contractPartners.${elementPrefix}.subTitle`)}
            data-testid={"contractPartner-pageHeader-container"}
            onBack={() => navigate("/contract-partners/list", { state })}
          />

          <ConfigProvider
            form={{
              /* eslint-disable no-template-curly-in-string */
              validateMessages: {
                required: "${label} " + t("contractPartners.labelIsRequired"),
              },
            }}
          >
            <Form
              onValuesChange={handleFormUpdate}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              initialValues={{ isActive: true, ...initialData }}
            >
              <Fieldset title={t(`contractPartners.${elementPrefix}.detailsSectionTitle`)}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      data-testid={"contractPartner-supplier"}
                      labelCol={{ span: 8 }}
                      name={["supplier", "id"]}
                      label={t("contractPartners.supplier")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled={isEditing}
                        showSearch={true}
                        placeholder={t("app.placeholder")}
                        optionFilterProp="label"
                        filterOption={handleFilter}
                        allowClear
                      >
                        {suppliers?.length &&
                          suppliers
                            .sort((a, b) => a.supplierShortId - b.supplierShortId)
                            .map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.countryId} {item.supplierShortId}
                                {" | "}
                                {item.name}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-communicationLanguage"
                      labelCol={{ span: 8 }}
                      name={"communication"}
                      label={t("contractPartners.communicationLanguage")}
                      rules={[{ required: true }]}
                    >
                      <Select placeholder={t("app.placeholder")} allowClear onClick={checkValidity}>
                        {availableLanguages.map((language) => (
                          <Option key={language.key} value={language.key}>
                            {language.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-purchaseSections"
                      labelCol={{ span: 8 }}
                      name={"purchaseSections"}
                      label={t("contractPartners.purchaseSections")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        mode="multiple"
                        placeholder={t("app.placeholder")}
                        allowClear
                        filterOption={handleFilter}
                        onChange={(values) => {
                          const finalSelectedOptions = values.includes("*")
                            ? [{ label: "*", value: "*" }]
                            : values
                                .map((selectedValue: string) => {
                                  const selectedOption = purchaseSections?.find(
                                    (purchaseSection) => purchaseSection.id === selectedValue,
                                  );
                                  return selectedOption
                                    ? { label: selectedOption.name, value: selectedOption.id }
                                    : null;
                                })
                                .filter(Boolean);

                          form.setFieldValue("purchaseSections", finalSelectedOptions);
                        }}
                      >
                        <Option data-testid="contractPartner-purchaseSectionsAllOption" value="*">
                          *
                        </Option>
                        {purchaseSections
                          ?.filter((purchaseSection) => purchaseSection.countryId === selectedSupplier?.countryId)
                          .map((purchaseSection) => (
                            <Option key={purchaseSection.id} value={purchaseSection.id}>
                              {purchaseSection.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-specialSettlementCompanyCode"
                      labelCol={{ span: 8 }}
                      name={"specialSettlementCompanyCode"}
                      label={t("contractPartners.specialSettlementCompanyCode")}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-ipDurationDays"
                      labelCol={{ span: 8 }}
                      name={"ipDurationDays"}
                      label={t("contractPartners.ipDurationDays")}
                    >
                      <Input type="number" min={0} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-popDurationDays"
                      labelCol={{ span: 8 }}
                      name={"popDurationDays"}
                      label={t("contractPartners.popDurationDays")}
                    >
                      <Input type="number" min={0} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-settlementType"
                      labelCol={{ span: 8 }}
                      name={"settlementType"}
                      label={t("contractPartners.settlementType")}
                    >
                      <Select placeholder={t("app.placeholder")} allowClear>
                        {settlementTypes.map((item) => (
                          <Option key={item.key} value={item.key}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-returnsIncluded"
                      labelCol={{ span: 8 }}
                      name={"customerReturnsIncluded"}
                      label={t("contractPartners.returnsIncluded")}
                      rules={[{ required: true }]}
                    >
                      <Select placeholder={t("app.placeholder")}>
                        {returnsIncluded.map((item) => (
                          <Option key={item.key} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-isOnboarded"
                      labelCol={{ span: 8 }}
                      name="isOnboarded"
                      label={t("contractPartners.isOnboarded")}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-hasSpecialPop"
                      labelCol={{ span: 8 }}
                      name="hasSpecialPop"
                      label={t("contractPartners.hasSpecialPop")}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-isConditionApprovalBlacklisted"
                      labelCol={{ span: 8 }}
                      name="isConditionApprovalBlacklisted"
                      label={t("contractPartners.isConditionApprovalBlacklisted")}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-recalcOptionEnabled"
                      labelCol={{ span: 8 }}
                      name="recalcOptionEnabled"
                      label={t("contractPartners.recalcOptionEnabled")}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-recalcDurationDays"
                      labelCol={{ span: 8 }}
                      name="recalcDurationDays"
                      label={t("contractPartners.recalcDurationDays")}
                      dependencies={["recalcOptionEnabled"]}
                    >
                      <Input type="number" min={1} disabled={!recalcOptionEnabled} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-isPopApprovalBlacklisted"
                      labelCol={{ span: 8 }}
                      name="isPopApprovalBlacklisted"
                      label={t("contractPartners.isPopApprovalBlacklisted")}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-testid="contractPartner-isActive"
                      labelCol={{ span: 8 }}
                      name="isActive"
                      label={t("contractPartners.isActive")}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                {isEditing && (
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        data-testid="contractPartner-createdBy"
                        labelCol={{ span: 8 }}
                        name="createdBy"
                        label={t("contractPartners.createdBy")}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        data-testid="contractPartner-updatedBy"
                        labelCol={{ span: 8 }}
                        name="updatedBy"
                        label={t("contractPartners.updatedBy")}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Fieldset>

              <Row justify="end" gutter={10}>
                <Button data-testid="contractPartner-saveButton" type="primary" htmlType="submit">
                  {t(isEditing ? "app.save" : "app.create")}
                </Button>
                <Button
                  data-testid="contractPartner-cancelButton"
                  htmlType="button"
                  onClick={() =>
                    form.isFieldsTouched() && isFormDirty
                      ? setIsCancelModalOpen(true)
                      : navigate("/contract-partners/list", { state })
                  }
                >
                  {t("app.cancel")}
                </Button>
              </Row>
            </Form>
          </ConfigProvider>
        </div>
        <Modal
          data-testid={"contractPartner-leavePageModal"}
          title={t("app.leavePage")}
          visible={isCancelModalOpen}
          onOk={() => navigate("/contract-partners/list", { state })}
          okType="danger"
          okText={t("app.leavePageButtonLabel")}
          onCancel={() => setIsCancelModalOpen(false)}
        >
          {t("app.leavePageLabel")}
        </Modal>
      </>
    );
  }
  return null;
};

export default Editor;
