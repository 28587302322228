import { Fieldset } from "Components/Fieldset/Fieldset";
import { Form, Input, Switch } from "antd";
import { useTranslation } from "react-i18next";

export const UserForm = () => {
  const { t } = useTranslation();

  return (
    <Fieldset title={t("editUser.detailsSectionTitle")}>
      <Form.Item
        data-testid="createUser-lastname"
        name="lastname"
        label={t("user.lastname")}
        labelCol={{ span: 4 }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        data-testid="createUser-firstname"
        name="firstname"
        label={t("user.firstname")}
        labelCol={{ span: 4 }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        data-testid="createUser-email"
        name="email"
        label={t("user.email")}
        labelCol={{ span: 4 }}
        rules={[
          { required: true },
          {
            type: "email",
            message: t("createUser.invalidEmail"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        data-testid="createUser-username"
        name="username"
        normalize={(value: string) => value.replace(/\s/g, "")}
        label={t("user.username")}
        labelCol={{ span: 4 }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        data-testid="createUser-isIndustrypartner"
        name="isIndustryPartner"
        label={t("user.isIndustryPartner")}
        valuePropName="checked"
        labelCol={{ span: 4 }}
      >
        <Switch />
      </Form.Item>
    </Fieldset>
  );
};
