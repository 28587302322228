import { Modal, notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";

import { resolver, serviceUrl } from "../../Config/serviceConfig";
import useAppContext from "../../Hooks/useAppContext";

const ActionCell = ({
  id,
  firstname,
  lastname,
  roles,
}: {
  id: number;
  firstname: string;
  lastname: string;
  roles: string[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const usersListUrl = serviceUrl.getUsersList();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isSuperUserAdmin: currentUserIsSuperAdmin, user: currentUser } = useAppContext();
  const editedUserHasAdminRoles = roles.some(
    (role: string) => (role === "SUPERUSERADMIN" || role === "USERADMIN") && Number(currentUser.id) !== Number(id),
  );
  const hasDeleteRoleForThisUser = !currentUserIsSuperAdmin && editedUserHasAdminRoles;

  const deleteUserMutation = useMutation(() => resolver.deleteUser(id), {
    onSuccess: async () => {
      notification.success({
        message: t("deleteUser.successRequest"),
        description: t("deleteUser.successRequestDescription"),
        placement: "topRight",
        duration: 2,
      });
      queryClient.invalidateQueries(usersListUrl);
    },
    onError: () => {
      notification.error({
        message: t("deleteUser.failedRequest"),
        description: t("deleteUser.failedRequestDescription"),
        placement: "topRight",
      });
    },
  });

  const handleDelete = () => {
    deleteUserMutation.mutate();
    setIsModalOpen(false);
  };

  return (
    <>
      <span style={{ display: "flex" }}>
        <EditTwoTone
          data-testid={`userList-editButton-${lastname}`}
          onClick={() => navigate(`/user/edit/${id}`, { state })}
          twoToneColor={hasDeleteRoleForThisUser ? "#aeaeae" : ""}
          style={{ fontSize: "20px", margin: "0 5px" }}
        />
        <DeleteTwoTone
          data-testid={`userList-deleteButton-${lastname}`}
          twoToneColor={hasDeleteRoleForThisUser ? "#aeaeae" : "#990000"}
          onClick={() => (hasDeleteRoleForThisUser ? null : setIsModalOpen(true))}
          style={{ fontSize: "20px", margin: "0 5px" }}
        />
      </span>

      <Modal
        title={t("deleteUser.modalTitle")}
        visible={isModalOpen}
        onOk={handleDelete}
        okType="danger"
        okText={t("deleteUser.deleteButtonLabel")}
        onCancel={() => setIsModalOpen(false)}
      >
        {`${t("deleteUser.modalLabel")} ${firstname} ${lastname}?`}
      </Modal>
    </>
  );
};

export default ActionCell;
