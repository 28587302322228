import styles from "./Fieldset.module.scss";

export const Fieldset = ({ children, title }: any) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>
      {children}
    </div>
  );
};
