import axios from "axios";
import { ContractPartnerData, ContractPartnerListResponse, OrderBy, Paging, UserListResponse, WorkItemsFilterRequest } from "../types";
interface newUserType {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
}

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_MOCK_DB
      ? `http://localhost:3001/api`
      : `http://localhost:3001`
    : `${process.env.REACT_APP_DOMAIN}/api`;

const queryFilter = async function (url: string, fetchOptions: RequestInit = {}): Promise<any> {
  const defaultOptions: typeof fetchOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "same-origin",
    redirect: "error",
  };
  const request = {
    ...defaultOptions,
    ...fetchOptions,
  };
  return fetch(url, request);
};

export const serviceUrl = {
  getUserData: () => `${BASE_URL}/users/currentUser`,
  getUsersList: () => `${BASE_URL}/users/list`,
  getManufacturers: (filterValue?: string) => `${BASE_URL}/manufacturers${filterValue ? `?filter=${filterValue}` : ""}`,
  getStores: (filterValue?: string) => `${BASE_URL}/stores${filterValue ? `?filter=${filterValue}` : ""}`,
  getSuppliers: (filterValue?: string) => `${BASE_URL}/suppliers${filterValue ? `?filter=${filterValue}` : ""}`,
  getUser: (id: number | string) => `${BASE_URL}/users/${id}`,
  getCountries: () => `${BASE_URL}/countries`,
  createNewUser: () => `${BASE_URL}/users`,
  updateUser: (id: string) => `${BASE_URL}/users/${id}`,
  deleteUser: (id: number) => `${BASE_URL}/users/${id}`,
  logout: () => `${BASE_URL}/logout`,
};

export const buildQueryString = (url: string, paging: Paging | {}) => {
  const orderBy = 'orderBy' in paging && Array.isArray(paging.orderBy) && paging.orderBy.length !== 0 ? paging.orderBy.join(',') : null;

  return Object.keys(paging).length ? `${url}?${queryStringify({ ...paging, orderBy })}` : `${url}`;
};

export const buildOrderBy = (orderBy: string, ascending: boolean): OrderBy => {
  return `${orderBy} ${ascending ? 'ASC' : 'DESC'}`;
};

/**
 * Stringify an object for use in a query string.
 *
 * @param {Object} obj - The object.
 * @param {string} prefix - When nesting, the parent key.
 *     keys in `obj` will be stringified as `prefix[key]`.
 * @returns {string}
 */
export function queryStringify(obj: { [x: string]: any }, prefix?: string): string {
  let pairs = [];
  for (let key in obj) {
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          continue;
      }

      let value = obj[key];
      let enkey = encodeURIComponent(key);
      let pair;
      if (typeof value === 'object') {
          pair = queryStringify(value, prefix ? prefix + '[' + enkey + ']' : enkey);
      } else {
          pair = (prefix ? prefix + '[' + enkey + ']' : enkey) + '=' + encodeURIComponent(value);
      }
      pairs.push(pair);
  }
  return pairs.join('&');
}

export const businessAdminUrl = {
  getContractPartner: (id: number) => `${BASE_URL}/business-admin/contract-partners/${id}`,
  getPurchaseSections: () => `${BASE_URL}/business-admin/purchase-sections`,
  getContractPartnersList: () => `${BASE_URL}/business-admin/contract-partners/list`,
  getContractPartnerSuppliers: () => `${BASE_URL}/business-admin/suppliers`,
  updateContractPartner: (id: number) => `${BASE_URL}/business-admin/contract-partners/${id}`,
  createContractPartner: () => `${BASE_URL}/business-admin/contract-partners`,
};

export const resolver = {
  query: (url: string, setServerVersion?: Function) =>
    axios.get(url).then((response) => {
      if (setServerVersion) setServerVersion(response.headers["x-service-version"]);

      return response.data;
    }),
  getUsers: async (paging: Paging, filter: WorkItemsFilterRequest): Promise<UserListResponse> => {
    return queryFilter(buildQueryString(serviceUrl.getUsersList(), paging), {
      method: "POST",
      body: JSON.stringify(filter),
    }).then((response) => response.json());
  },
  createNewUser: (body: newUserType) => axios.post(serviceUrl.createNewUser(), body).then((response) => response.data),
  updateUser: (id: string, body: newUserType) =>
    axios.put(serviceUrl.updateUser(id), body).then((response) => response.data),
  deleteUser: (id: number) => axios.delete(serviceUrl.deleteUser(id)).then((response) => response.data),
  updateContractPartner: (id: number, body: ContractPartnerData) =>
    axios.put(businessAdminUrl.updateContractPartner(id), body).then((response) => response.data),
  createContractPartner: (body: ContractPartnerData) =>
    axios.post(businessAdminUrl.createContractPartner(), body).then((response) => response.data),
  getManufacturers: (filterValue: string) =>
    axios.get(serviceUrl.getManufacturers(filterValue)).then((response) => response.data),
  getSuppliers: (filterValue: string) =>
    axios.get(serviceUrl.getSuppliers(filterValue)).then((response) => response.data),
  getStores: (filterValue: string) => axios.get(serviceUrl.getStores(filterValue)).then((response) => response.data),
  getContractPartnerList: async (paging: Paging, filter: WorkItemsFilterRequest): Promise<ContractPartnerListResponse> => {
    return queryFilter(buildQueryString(businessAdminUrl.getContractPartnersList(), paging), {
      method: "POST",
      body: JSON.stringify(filter),
    }).then((response) => response.json());
  },
};
