import { useLocation, useNavigate } from "react-router-dom";

import { EditTwoTone } from "@ant-design/icons";

const ActionCell = ({ id }: { id: number }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <span style={{ display: "flex" }}>
      <EditTwoTone
        data-testid={`contractPartnerList-editButton`}
        twoToneColor={""}
        onClick={() => navigate(`/contract-partners/edit/${id}`, { state })}
        style={{ fontSize: "20px", margin: "0 5px" }}
      />
    </span>
  );
};

export default ActionCell;
