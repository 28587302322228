import { Result } from "antd";
import React from "react";
import styles from "./NotAuthorized.module.css";

const NotAuthorized: React.FC = () => (
  <div className={styles.container}>
    <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />
  </div>
);

export default NotAuthorized;
