import { Tooltip, Space } from "antd";
import moment from "moment";

const DateWithTooltip = ({ date, username }: { date: Date; username: string }) => {
  const dateFormatted = moment(date).format("DD.MM.YYYY HH:mm");
  const dateRelative = moment(date).fromNow();
  const tooltipTitle = (
    <Space>
      <span>{username}</span>
      <span>{dateFormatted}</span>
    </Space>
  );

  return <Tooltip title={tooltipTitle}>{dateRelative}</Tooltip>;
};

export { DateWithTooltip };
