import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Col, Row } from "antd";

import HeaderComponent from "../HeaderComponent/HeaderComponent";
import SideMenu from "../SideMenu/SideMenu";
import Footer from "../Footer/Footer";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <HeaderComponent title={t("app.title")} />
      <Row style={{ flex: 1, display: "flex" }}>
        <Col span={3}>
          <SideMenu />
        </Col>
        <Col span={21}>{children}</Col>
      </Row>
      <Footer />
    </div>
  );
};

export default Layout;
