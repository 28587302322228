import "antd/dist/antd.min.css";
import "./index.scss";
import "./i18n";

import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter } from "react-router-dom";

import App from "./App";
import createMockServer from "./mockDB/mock-server";

if (process.env.REACT_APP_MOCK_DB === "true") {
  createMockServer({ port: Number(process.env.PORT) });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </HashRouter>
  </React.StrictMode>,
);
