import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { resolver } from "../../../Config/serviceConfig";
import { ContractPartnerData } from "../../../types";

import Editor from "../Editor";
import { AxiosError } from "axios";

export default function ContractPartnerCreate() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const createContractPartnerMutation = useMutation(
    (values: ContractPartnerData) => resolver.createContractPartner(values),
    {
      onSuccess: async () => {
        notification.success({
          message: t("contractPartners.successCreateRequestDescription"),
          description: t("contractPartners.successCreateRequestDescription"),
          placement: "topRight",
          duration: 2,
        });
        // setting false so that after save, to not be prompted the modal when clicking back, but just redirecting to the contract partner list page
        navigate("/contract-partners/list", { state });
      },
      onError: (error: AxiosError) => {
        notification.error({
          message: t("contractPartners.failedRequest"),
          description:
            error.response?.status === 409
              ? t("contractPartners.create.supplierAlreadyUsed")
              : t("contractPartners.failedCreateRequestDescription"),
          placement: "topRight",
        });
      },
    },
  );

  return <Editor isEditing={false} onSave={createContractPartnerMutation} />;
}
