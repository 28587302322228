import { UsergroupAddOutlined, BuildOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React, { useContext } from "react";
import LogoutComponent from "../Logout/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../../State/context/appContext";

const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { isBusinessAdmin, isSuperUserAdmin } = useContext(AppContext);

  const handleRouteChange = (url: string) => navigate(url);

  const menuItems = [];

  if (isSuperUserAdmin) {
    menuItems.push({
      key: "/users-list",
      label: "Users list",
      icon: <UsergroupAddOutlined />,
      onClick: () => handleRouteChange("/users-list"),
    });
  }

  if (isBusinessAdmin) {
    menuItems.push({
      key: "/contract-partners/list",
      label: "Contract Partners",
      icon: <BuildOutlined />,
      onClick: () => handleRouteChange("/contract-partners/list"),
    });
  }

  menuItems.push({
    onClick(): void {},
    key: "logout",
    label: "",
    icon: <LogoutComponent />,
  });

  return (
    <Menu
      data-testid={"sideMenu-container"}
      style={{ paddingTop: "1rem" }}
      selectedKeys={[currentPath]}
      items={menuItems}
    />
  );
};

export default SideMenu;
