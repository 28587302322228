import { TFunction } from "i18next";

import { Country, Filters, Sorter, TableContractPartnerDataType, TableFilterTypes } from "../../../types";
import ActionCell from "./ActionCell";

import type { ColumnsType } from "antd/lib/table";
import { settlementTypes } from "../constants";
import { DateWithTooltip } from "Components/Date/DateWithTooltip";
import { Space, Tag, Typography } from "antd";

const { Text } = Typography;

const countryFilters: TableFilterTypes[] = [
  {
    value: Country.DE,
    text: Country.DE,
  },
  {
    value: Country.ES,
    text: Country.ES,
  },
  {
    value: Country.BE,
    text: Country.BE,
  },
  {
    value: Country.LU,
    text: Country.LU,
  },
  {
    value: Country.NL,
    text: Country.NL,
  },
];

export const columns = (t: TFunction, sortation: Sorter, filteredInfo: Filters): ColumnsType<TableContractPartnerDataType> => [
  {
    title: t("contractPartners.id"),
    dataIndex: "supplierShortId",
    key: "supplierShortId",
    render: (_, record) => record.supplier.supplierShortId,
    defaultSortOrder: "ascend",
    sorter: true
  },
  {
    title: t("contractPartners.supplier"),
    dataIndex: "supplierName",
    key: "supplierName",
    render: (text, record) => record.supplier.name ?? "---",
  },
  {
    title: t("app.countryId"),
    dataIndex: "countryId",
    key: "countryId",
    render: (text, record) => record.supplier.countryId,
    filters: [...countryFilters],
    filteredValue: filteredInfo["countryId"] || null,
  },
  {
    title: t("contractPartners.isOnboarded"),
    dataIndex: "isOnboarded",
    key: "isOnboarded",
    render: (text) => (text ? `${t("app.yes")}` : `${t("app.no")}`),
    sorter: true
  },
  {
    title: t("contractPartners.isActive"),
    dataIndex: "isActive",
    key: "isActive",
    render: (text) => (text ? `${t("app.yes")}` : `${t("app.no")}`),
    sorter: true
  },
  {
    title: t("contractPartners.isConditionApprovalBlacklisted"),
    dataIndex: "isConditionApprovalBlacklisted",
    key: "isConditionApprovalBlacklisted",
    render: (text) => (text ? `${t("app.yes")}` : `${t("app.no")}`),
    sorter: true
  },
  {
    title: t("contractPartners.nationalRenegotiated"),
    dataIndex: "nationalRenegotiated",
    key: "nationalRenegotiated",
    render: (text) => (text ? `${t("app.yes")}` : `${t("app.no")}`),
    sorter: true
  },
  {
    title: t("contractPartners.isPopApprovalBlacklisted"),
    dataIndex: "isPopApprovalBlacklisted",
    key: "isPopApprovalBlacklisted",
    render: (text) => (text ? `${t("app.yes")}` : `${t("app.no")}`),
    sorter: true
  },
  {
    title: t("contractPartners.specialSettlementCompanyCode"),
    dataIndex: "specialSettlementCompanyCode",
    key: "specialSettlementCompanyCode",
    render: (text) => (text ? text : "---"),
  },
  {
    title: t("contractPartners.settlementType"),
    dataIndex: "settlementType",
    key: "settlementType",
    render: (text) => settlementTypes.find((type) => type.key === text)?.label || "---",
  },
  {
    title: t("contractPartners.purchaseSections"),
    dataIndex: "purchaseSections",
    key: "purchaseSections",
    render: (_, record) => (
      <Space direction="vertical" size={0}>
        {record.purchaseSections.map((section) => (
          <Tag color="transparent" key={section}>
            <Text>{section}</Text>
          </Tag>
        ))}
      </Space>
    ),
  },
  {
    title: t("contractPartners.updatedAt"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (date: string, record) => <DateWithTooltip date={new Date(date)} username={record.updatedBy} />,
    sorter: true
  },
  {
    title: t("contractPartners.createdAt"),
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string, record) => <DateWithTooltip date={new Date(date)} username={record.createdBy} />,
    sorter: true
  },
  {
    title: t("app.actions"),
    key: "actions",
    dataIndex: "actions",
    render: (_text, record) => <ActionCell id={record.id} />,
  },
];
