import { PageHeader, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import styles from "./HeaderComponent.module.css";

interface HeaderComponentInterface {
  title?: string;
  subTitle?: string;
  extra?: JSX.Element[];
  onBack?: () => void;
}

const HeaderComponent: React.FC<HeaderComponentInterface> = (props) => {
  const { t, i18n } = useTranslation();

  const menu = (
    <Menu
      onClick={(item) => changeLanguage(item.key)}
      items={[
        {
          label: t("app.english"),
          key: "en",
        },
        {
          label: t("app.german"),
          key: "de",
        },
        {
          label: t("app.spanish"),
          key: "es",
        },
      ]}
    />
  );

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <PageHeader
      className={styles.container}
      avatar={{ src: "static/logo.png" }}
      {...props}
      data-testid="pageHeader-container"
      extra={[
        props?.extra,
        <Dropdown overlay={menu} key="select-language">
          <Space data-testid="pageHeader-languageDropdown">
            {t("app.language")}
            <DownOutlined />
          </Space>
        </Dropdown>,
      ]}
    />
  );
};

export default HeaderComponent;
