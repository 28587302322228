import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  PageHeader,
  Result,
  Row,
  Select,
  Spin,
} from "antd";
import { AxiosError } from "axios";
// @ts-ignore
import combos from "combos";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "ts-debounce";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { Fieldset } from "../../../Components/Fieldset/Fieldset";
import { UserForm } from "../../../Components/UserForm/UserForm";
import { resolver, serviceUrl } from "../../../Config/serviceConfig";
import useAppContext from "../../../Hooks/useAppContext";
import { UserRightsTypes } from "../../../types";
import { defaultRoles, rolesOptions, validityAreaOptions } from "./constants";
import styles from "./UserEdit.module.scss";
import {
  Country,
  InitialDataUserRoles,
  InitialUserDetails,
  ManufacturersRoles,
  StoresRoles,
  SuppliersRoles,
} from "./userEditTypes";

const { Option, OptGroup } = Select;

export default function UserEdit() {
  const [initialValuesSet, setInitialValuesSet] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [availableManufacturers, setAvailableManufacturers] = useState<ManufacturersRoles[]>([]);
  const [availableSuppliers, setAvailableSuppliers] = useState<SuppliersRoles[]>([]);
  const [availableStores, setAvailableStores] = useState<StoresRoles[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const userId = location.pathname.split("/").pop() || "";
  const userUrl = serviceUrl.getUser(userId);
  const userDataUrl = serviceUrl.getUserData();
  const countriesUrl = serviceUrl.getCountries();
  const queryClient = useQueryClient();
  const { user: currentUser, isSuperUserAdmin: currentUserIsSuperAdmin } = useAppContext();
  const selectedUserRights = (index: number) => form.getFieldsValue().userRights?.[index];

  const {
    data: userData,
    isLoading,
    isError,
    isSuccess,
    error,
  }: {
    data: InitialUserDetails | undefined;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    error: AxiosError | null;
  } = useQuery({
    queryKey: userUrl,
    queryFn: () => resolver.query(userUrl),
  });

  const { state } = useLocation();

  const { data: countries }: { data: Country[] | undefined } = useQuery({
    queryKey: countriesUrl,
    queryFn: () => resolver.query(countriesUrl),
  });

  const getSuppliersMutation = useMutation((value: string) => resolver.getSuppliers(value), {
    onSuccess: async (data) => data,
  });

  const getManufacturersMutation = useMutation((value: string) => resolver.getManufacturers(value), {
    onSuccess: async (data) => data,
  });

  const getStoresMutation = useMutation((value: string) => resolver.getStores(value), {
    onSuccess: async (data) => data,
  });

  const updateUserMutation = useMutation((values: InitialUserDetails) => resolver.updateUser(userId, values), {
    onSuccess: async () => {
      notification.success({
        message: t("editUser.successRequest"),
        description: t("editUser.successRequestDescription"),
        placement: "topRight",
        duration: 2,
      });
      setIsFormDirty(false);
      queryClient.invalidateQueries(userUrl);
      queryClient.invalidateQueries(userDataUrl);
      navigate("/users-list", { state: state });
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: t("editUser.failedRequest"),
        description:
          error.response?.status === 409
            ? t("editUser.unavailableUserOrEmail")
            : t("editUser.failedRequestDescription"),
        placement: "topRight",
      });
    },
  });

  const checkValidity = () => form.validateFields();

  const onFinish = (values: InitialUserDetails) => {
    let newUserRights: InitialDataUserRoles[] = [];
    const hasSuperUserRole = values.userRights.some((user: InitialDataUserRoles) => user.role === "SUPERUSERADMIN");

    // if the user is a SUPERADMIN, then there is no reason for the USERADMIN role
    if (hasSuperUserRole) {
      values.userRights = values.userRights.filter((user: InitialDataUserRoles) => user.role !== "USERADMIN");
    }

    values.userRights.forEach((userRight, index) => {
      newUserRights[index] = combos({
        manufacturerId: userRight.manufacturerId.length ? [...userRight.manufacturerId] : [null],
        storeId: userRight.storeId.length ? [...userRight.storeId] : [null],
        supplierId: userRight.supplierId.length ? [...userRight.supplierId] : [null],
        validityArea: userRight.validityAreas.length ? [...userRight.validityAreas] : [null],
        countryId: [userRight.countryId],
        salesChannelId: [userRight.salesChannelId],
        clusterId: [userRight.clusterId],
        role: [userRight.role],
        namespace: [rolesOptions[userRight.role]],
      });
    });
    values.userRights = newUserRights.flat();

    // if there are multiple "SUPERUSERADMIN" roles reduce them to one.
    const hasMultipleSuperAdminRoles = values.userRights.filter(
      (user) => user.namespace === "CMADMIN" && user.role === "SUPERUSERADMIN",
    );
    if (hasMultipleSuperAdminRoles?.length > 1) {
      const adminRoleIndex = values.userRights.findIndex((user: InitialDataUserRoles) => user.namespace === "CMADMIN");
      values.userRights = values.userRights.filter((_: any, index: number) => index !== adminRoleIndex);
    }

    updateUserMutation.mutate(values);
  };

  const handleSupplierSearch = (values: string, index: number) => {
    const params = `${values}&country=${selectedUserRights(index).countryId}`;
    getSuppliersMutation.mutate(params, {
      onSuccess: (data) => setAvailableSuppliers(data),
    });
  };

  const handleManufacturerSearch = (values: string, index: number) => {
    const params = `${values}&country=${selectedUserRights(index).countryId}`;
    getManufacturersMutation.mutate(params, {
      onSuccess: (data) => setAvailableManufacturers(data),
    });
  };

  const handleStoreSearch = (values: string, index: number) => {
    const params = `${values}&country=${selectedUserRights(index).countryId}`;
    getStoresMutation.mutate(params, {
      onSuccess: (data) => setAvailableStores(data),
    });
  };

  const handleFormUpdate = () => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };

  // because of custom behaviour, we need to sanitize and update the initial data
  const handleInitialData = (userData: InitialUserDetails) => {
    let stateManufacturers: ManufacturersRoles[] = [];
    let stateStores: StoresRoles[] = [];
    let stateSuppliers: SuppliersRoles[] = [];
    // updating suppliers, manufacturers, stores, and validity areas, so if there are
    // multiple items with the same roles, to display them only once.
    const newUserRights = userData.userRights.reduce((acc: InitialDataUserRoles[], item: InitialDataUserRoles) => {
      if (
        acc.some((rights: InitialDataUserRoles) => rights.role === item.role && rights.countryId === item.countryId)
      ) {
        const indexOfElement = acc.findIndex(
          (rights: InitialDataUserRoles) => rights.role === item.role && rights.countryId === item.countryId,
        );
        // only updating the list if there are valid IDs and they haven't already been added
        item.manufacturer.id &&
          !stateManufacturers.some(
            (stateManufacturer: ManufacturersRoles) => stateManufacturer.id === item.manufacturer.id,
          ) &&
          stateManufacturers.push(item.manufacturer);
        item.store.id &&
          !stateStores.some((stateStore: StoresRoles) => stateStore.id === item.store.id) &&
          stateStores.push(item.store);
        item.supplier.id &&
          !stateSuppliers.some((stateSupplier: SuppliersRoles) => stateSupplier.id === item.supplier.id) &&
          stateSuppliers.push(item.supplier);

        acc[indexOfElement].supplierId.push(item.supplier.id);
        acc[indexOfElement].manufacturerId.push(item.manufacturer.id);
        acc[indexOfElement].storeId.push(item.store.id);
        acc[indexOfElement].validityAreas.push(item.validityArea);
        acc[indexOfElement].manufacturers.push(item.manufacturer);
        acc[indexOfElement].stores.push(item.store);
        acc[indexOfElement].suppliers.push(item.supplier);
        return acc;
      } else {
        stateManufacturers.push(item.manufacturer);
        stateStores.push(item.store);
        stateSuppliers.push(item.supplier);
        return [
          ...acc,
          {
            ...item,
            validityAreas: [item.validityArea],
            manufacturerId: [item.manufacturer.id],
            supplierId: [item.supplier.id],
            storeId: [item.store.id],
            manufacturers: [{ ...item.manufacturer }],
            stores: [{ ...item.store }],
            suppliers: [{ ...item.supplier }],
          },
        ];
      }
    }, []);

    // remove duplicates and empty elements from lists. In case "*" is selected, remove all other elements.
    newUserRights.map((item: InitialDataUserRoles) => {
      item.manufacturerId = item.manufacturerId.some((manufacturer: string | number) => manufacturer.toString() === "*")
        ? ["*"]
        : [...new Set(item.manufacturerId)].filter((manufacturer) => !!manufacturer);
      item.supplierId = item.supplierId.some((supplier: string | number) => supplier.toString() === "*")
        ? ["*"]
        : [...new Set(item.supplierId)].filter((supplier) => !!supplier);
      item.storeId = item.storeId.some((store: string | number) => store.toString() === "*")
        ? ["*"]
        : [...new Set(item.storeId)].filter((store) => !!store);
      item.validityAreas = item.validityAreas.some((validityArea: string) => validityArea === "*")
        ? ["*"]
        : [...new Set(item.validityAreas)];
      return item;
    });

    if (!initialValuesSet) {
      // set initial values from the dropdowns with items that only have Names. (not id's because the "*" only has id and not a name.)
      setAvailableManufacturers(stateManufacturers.filter((manufacturer) => manufacturer.name));
      setAvailableStores(stateStores.filter((store) => store.name));
      setAvailableSuppliers(stateSuppliers.filter((supplier) => supplier.name));
      setInitialValuesSet(true);
    }

    return { ...userData, userRights: [...newUserRights] };
  };

  const isSelectedRoleIp = (index: number) => {
    const allUserRights = form.getFieldsValue().userRights;
    if (allUserRights) {
      return allUserRights[index]["role"] === "IP";
    }
    return false;
  };

  const handleCheck = (value: string, index: number, field: string) => {
    const newUserDetails = form.getFieldsValue();
    const newUserRights = newUserDetails.userRights;

    const setFieldsValueAndReturn = (updatedUserRights: InitialDataUserRoles[]) => {
      form.setFieldsValue({ userRights: [...updatedUserRights] });
    };

    if (field === "role" && value === "IP" && newUserRights[index]["supplierId"][0] === "*") {
      newUserRights[index]["supplierId"] = undefined;
    }

    if (value === "*") {
      newUserRights[index][field] = ["*"];
      setFieldsValueAndReturn(newUserRights);
    } else if (field === "countryId") {
      newUserDetails.userRights[index] = {
        ...defaultRoles,
        role: newUserDetails.userRights[index].role,
        validityAreas: newUserDetails.userRights[index].validityAreas,
        supplierId: newUserDetails.userRights[index].supplierId,
      };
      newUserDetails.userRights[index][field] = value;
      setFieldsValueAndReturn(newUserDetails.userRights);
    } else if (field === "role") {
      if (value === "IP") {
        form.setFieldsValue({
          isIndustryPartner: true,
        });
      }
      if (value === "SUPERUSERADMIN") {
        newUserRights[index] = {
          ...newUserRights[index],
          countryId: "*",
          validityAreas: ["*"],
          supplierId: ["*"],
          manufacturerId: ["*"],
          storeId: ["*"],
        };
      } else {
        newUserRights[index] = {
          ...defaultRoles,
          ...newUserRights[index],
          countryId: newUserRights[index].countryId === "*" ? "" : newUserRights[index].countryId,
        };
      }
      setFieldsValueAndReturn(newUserRights);
    } else {
      const fieldValues = form.getFieldsValue()?.userRights?.[index][field];
      if (Array.isArray(fieldValues) && fieldValues?.includes("*")) {
        newUserRights[index][field] = fieldValues.filter((value: string) => value !== "*");
        setFieldsValueAndReturn(newUserRights);
      }
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  if (!userId || isError || !userData) {
    if (error?.response?.status === 403) {
      navigate("/not-authorized");
    }

    return (
      <Result
        status="404"
        title="404"
        subTitle={t("editUser.404-description")}
        extra={
          <Button type="primary" onClick={() => navigate(`/users-list`, { state: state })}>
            {t("app.backHomeButton")}
          </Button>
        }
      />
    );
  }

  const editedUserHasAdminRoles = userData.userRights.some(
    // Allow the user to edit himself if he is a USERADMIN
    (rights: InitialDataUserRoles) =>
      Number(userData.id) !== Number(currentUser.id) &&
      (rights.role === "SUPERUSERADMIN" || rights.role === "USERADMIN"),
  );
  const hasDeleteRoleForThisUser = !currentUserIsSuperAdmin && editedUserHasAdminRoles;

  if (hasDeleteRoleForThisUser) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={t("editUser.403-description")}
        extra={
          <Button type="primary" onClick={() => navigate(`/users-list`, { state: state })}>
            {t("app.backHomeButton")}
          </Button>
        }
      />
    );
  }

  if (isSuccess) {
    const initialData = handleInitialData(userData);
    return (
      <>
        <div className={styles.formContainer}>
          <PageHeader
            className={styles.container}
            title={t("editUser.title")}
            subTitle={t("editUser.subTitle")}
            data-testid="userEdit-pageHeader-container"
            onBack={() => navigate("/users-list", { state: state })}
          />

          <ConfigProvider
            form={{
              /* eslint-disable no-template-curly-in-string */
              validateMessages: {
                required: "${label} " + t("editUser.labelIsRequired"),
              },
            }}
          >
            <Form
              onValuesChange={handleFormUpdate}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              initialValues={initialData}
            >
              <UserForm></UserForm>
              <Fieldset title={t("editUser.rightsSectionTitle")}>
                <div className={styles.legend}>{t("editUser.legend")}</div>
                <Form.List name="userRights">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => {
                        const editedUserRole =
                          initialData.userRights?.[index]?.role ?? form.getFieldsValue().userRights?.[index].role;
                        const isFieldDisabled = editedUserRole === "SUPERUSERADMIN";
                        const countryFieldValue = form.getFieldsValue().userRights?.[index]?.countryId;
                        const isCountryDisabled =
                          countries?.length === 1 ||
                          (!!initialData.userRights[index]?.countryId && countryFieldValue !== "");

                        return (
                          <div data-testid="editUser-rolesContainer" key={`field-${index}-${field.key}`}>
                            <Row justify="space-between">
                              <Col span={12}>
                                <Form.Item
                                  data-testid="userEdit-countryElement"
                                  labelCol={{ span: 6 }}
                                  name={[field.name, "countryId"]}
                                  label={t("user.countryId")}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    disabled={isCountryDisabled}
                                    placeholder={t("app.placeholder")}
                                    onSelect={(value: string) => handleCheck(value, index, "countryId")}
                                  >
                                    {countries?.map((country: Country) => {
                                      return (
                                        <Option key={`${country.id}-${index}`} value={country.id}>
                                          {country.id}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  data-testid="userEdit-roleElement"
                                  labelCol={{ span: 6 }}
                                  name={[field.name, "role"]}
                                  label={t("user.role")}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    disabled={!currentUserIsSuperAdmin && editedUserRole === "USERADMIN"}
                                    placeholder={t("app.placeholder")}
                                    allowClear
                                    onClick={checkValidity}
                                    onSelect={(value: string) => handleCheck(value, index, "role")}
                                  >
                                    <OptGroup label="CCM">
                                      {Object.keys(rolesOptions)
                                        .filter((role: string) => rolesOptions[role] === "CCM")
                                        .map((role) => (
                                          <Option key={`${role}-${index}`} value={role}>
                                            {role}
                                          </Option>
                                        ))}
                                    </OptGroup>
                                    {currentUser.userRights.some(
                                      (rights: UserRightsTypes) => rights.role === "SUPERUSERADMIN",
                                    ) ? (
                                      <OptGroup label="CMADMIN">
                                        {Object.keys(rolesOptions)
                                          .filter((role: string) => rolesOptions[role] === "CMADMIN")
                                          .map((role) => (
                                            <Option key={`${role}-${index}`} value={role}>
                                              {role}
                                            </Option>
                                          ))}
                                      </OptGroup>
                                    ) : null}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row justify="space-between">
                              <Col span={12}>
                                <Form.Item
                                  data-testid="userEdit-valityAreaElement"
                                  labelCol={{ span: 6 }}
                                  name={[field.name, "validityAreas"]}
                                  label={t("user.validityArea")}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    disabled={isFieldDisabled}
                                    mode="multiple"
                                    placeholder={t("app.placeholder")}
                                    allowClear
                                    onSelect={(value: string) => handleCheck(value, index, "validityAreas")}
                                  >
                                    {validityAreaOptions.map((area) => (
                                      <Option key={`${area}-${index}`} value={area}>
                                        {area}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  data-testid="userEdit-supplierElement"
                                  labelCol={{ span: 6 }}
                                  name={[field.name, "supplierId"]}
                                  label={t("user.supplierId")}
                                  rules={[{ required: isSelectedRoleIp(index) }]}
                                >
                                  <Select
                                    disabled={isFieldDisabled}
                                    mode="multiple"
                                    placeholder={t("app.placeholder")}
                                    optionFilterProp="label"
                                    onSearch={debounce((value) => handleSupplierSearch(value, index), 300)}
                                    filterOption={false}
                                    allowClear
                                    onBlur={() => setAvailableSuppliers([])}
                                    onSelect={(value: string) => handleCheck(value, index, "supplierId")}
                                  >
                                    {!isSelectedRoleIp(index) && (
                                      <Option data-testid="userEdit-supplierAllOption" value="*">
                                        *
                                      </Option>
                                    )}
                                    {availableSuppliers.map((item: SuppliersRoles, supplierIndex: number) => {
                                      return (
                                        <Option key={`${item.id}-${index}`} value={item.id}>
                                          {[
                                            availableSuppliers[supplierIndex].supplierShortId,
                                            availableSuppliers[supplierIndex].name,
                                          ].join(" | ")}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row justify="space-between">
                              <Col span={12}>
                                <Form.Item
                                  data-testid="userEdit-manufacturerElement"
                                  labelCol={{ span: 6 }}
                                  name={[field.name, "manufacturerId"]}
                                  label={t("user.manufacturerId")}
                                >
                                  <Select
                                    disabled={isFieldDisabled}
                                    mode="multiple"
                                    placeholder={t("app.placeholder")}
                                    onSearch={debounce((value) => handleManufacturerSearch(value, index), 300)}
                                    filterOption={false}
                                    maxTagCount={8}
                                    maxTagTextLength={25}
                                    allowClear
                                    onBlur={() => setAvailableManufacturers([])}
                                    onSelect={(value: string) => handleCheck(value, index, "manufacturerId")}
                                  >
                                    <Option data-testid="userEdit-manufacturerAllOption" value="*">
                                      *
                                    </Option>
                                    {availableManufacturers.map(
                                      (item: ManufacturersRoles, manufacturerIndex: number) => {
                                        return (
                                          <Option key={`${item.id}-${index}`} value={item.id}>
                                            {`${availableManufacturers[manufacturerIndex].id} | ${availableManufacturers[manufacturerIndex].name}`}
                                          </Option>
                                        );
                                      },
                                    )}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  data-testid="userEdit-storeElement"
                                  labelCol={{ span: 6 }}
                                  name={[field.name, "storeId"]}
                                  label={t("user.storeId")}
                                  rules={[
                                    ({ getFieldsValue }) => ({
                                      validator(_, value) {
                                        const userRole = getFieldsValue()?.userRights?.[index].role;
                                        if (userRole === "STORE" && (!value || value === "*")) {
                                          return Promise.reject(new Error(t("editUser.unsupportedStore")));
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <Select
                                    disabled={isFieldDisabled}
                                    mode="multiple"
                                    placeholder={t("app.placeholder")}
                                    onSearch={debounce((value) => handleStoreSearch(value, index), 300)}
                                    filterOption={false}
                                    allowClear
                                    onBlur={() => setAvailableStores([])}
                                    onSelect={(value: string) => handleCheck(value, index, "storeId")}
                                  >
                                    <Option data-testid="userEdit-storesAllOption" value="*">
                                      *
                                    </Option>
                                    {availableStores.map((item: StoresRoles, storeIndex: number) => {
                                      return (
                                        <Option key={`${item.id}-${index}`} value={item.id}>
                                          {`${availableStores[storeIndex].sapCode} | ${availableStores[storeIndex].name}`}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row justify="space-between">
                              <Col span={12}>
                                <Form.Item
                                  labelCol={{ span: 6 }}
                                  name={[field.name, "clusterId"]}
                                  label={t("user.clusterId")}
                                >
                                  <Input disabled={true} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  labelCol={{ span: 6 }}
                                  name={[field.name, "salesChannelId"]}
                                  label={t("user.salesChannelId")}
                                >
                                  <Input disabled={true} />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row justify="space-between">
                              <Col span={24}>
                                <Button
                                  data-testid="userEdit-removeRoleButton"
                                  disabled={!currentUserIsSuperAdmin && editedUserRole === "USERADMIN"}
                                  type="dashed"
                                  onClick={() => remove(field.name)}
                                  block
                                  danger
                                  icon={<MinusCircleOutlined />}
                                >
                                  {t("editUser.removeUserRightButton")}
                                </Button>
                              </Col>
                            </Row>

                            <Divider orientation="left" />
                          </div>
                        );
                      })}
                      <Form.Item>
                        <Button
                          data-testid="userEdit-addUserButton"
                          type="default"
                          onClick={() =>
                            add({
                              ...defaultRoles,
                              countryId: countries!.length > 1 ? "" : countries![0].id,
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          {t("editUser.addUserRightButton")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Row justify="end" gutter={10}>
                  <Button data-testid="userEdit-saveButton" type="primary" htmlType="submit">
                    {t("app.save")}
                  </Button>
                  <Button
                    data-testid="userEdit-cancelButton"
                    htmlType="button"
                    onClick={() =>
                      form.isFieldsTouched() && isFormDirty
                        ? setIsCancelModalOpen(true)
                        : navigate("/users-list", { state: state })
                    }
                  >
                    {t("app.cancel")}
                  </Button>
                </Row>
              </Fieldset>
            </Form>
          </ConfigProvider>
        </div>

        <Modal
          data-testid="editUser-leavePageModal"
          title={t("app.leavePage")}
          visible={isCancelModalOpen}
          onOk={() => navigate("/users-list", { state: state })}
          okType="danger"
          okText={t("app.leavePageButtonLabel")}
          onCancel={() => setIsCancelModalOpen(false)}
        >
          {t("app.leavePageLabel")}
        </Modal>
      </>
    );
  }
  return null;
}
