import { Modal, Space, Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { resolver, serviceUrl } from "../../Config/serviceConfig";

import { useNavigate } from "react-router-dom";
import { useState } from "react";

const userDataUrl = serviceUrl.getUserData();

const LogoutComponent: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleLogout = async () => {
    await resolver.query(serviceUrl.logout());

    await queryClient.invalidateQueries(userDataUrl);
    navigate("/users-list");
  };

  return (
    <Space>
      <Button type="ghost" onClick={showModal}>
        <LogoutOutlined /> Logout
      </Button>
      <Modal title={t("app.logout")} visible={isModalOpen} onOk={handleLogout} onCancel={handleCancel}>
        <p>{t("app.logoutMessage")}</p>
      </Modal>
    </Space>
  );
};

export default LogoutComponent;
