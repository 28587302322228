import i18n from "i18next";
import browserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationDE from "./i18n/de/translations.json";
import translationEN from "./i18n/en/translations.json";
import translationES from "./i18n/es/translations.json";

i18n
  .use(browserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: { translation: translationEN },
      de: { translation: translationDE },
      es: { translation: translationES },
    },
    interpolation: {
      escapeValue: false,
    },
  });
