export const availableLanguages = [
  {
    key: "de_DE",
    label: "DE",
  },
  {
    key: "en_GB",
    label: "EN",
  },
  {
    key: "es_ES",
    label: "ES",
  },
];

export const settlementTypes = [
  {
    key: "CREDIT",
    label: "Credit note",
  },
  {
    key: "CHARGE",
    label: "Claim",
  },
];

export const returnsIncluded = [
  {
    key: "false",
    value: false,
    label: "No",
  },
  {
    key: "true",
    value: true,
    label: "Yes",
  },
];
