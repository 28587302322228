import { Button, notification, Result, Spin } from "antd";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { resolver, businessAdminUrl } from "../../../Config/serviceConfig";
import { ContractPartnerData, EditContractPartnerDataType } from "../../../types";

import Editor from "../Editor";

export default function ContractPartnerEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const contractPartnerId = Number(location.pathname.split("/").pop());
  const contractPartnerUrl = businessAdminUrl.getContractPartner(contractPartnerId);
  const queryClient = useQueryClient();

  const {
    data: contractPartnerData,
    isLoading: isContractPartnerLoading,
    isError: isContractPartnerError,
    isSuccess: isContractPartnerSuccess,
    error: contractPartnerError,
  }: {
    data: ContractPartnerData | undefined;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    error: AxiosError | null;
  } = useQuery({
    queryKey: contractPartnerUrl,
    queryFn: () => resolver.query(contractPartnerUrl),
  });

  const updateContractPartnerMutation = useMutation(
    (values: ContractPartnerData) => resolver.updateContractPartner(contractPartnerId, values),
    {
      onSuccess: async () => {
        notification.success({
          message: t("contractPartners.successRequest"),
          description: t("contractPartners.successUpdateRequestDescription"),
          placement: "topRight",
          duration: 2,
        });
        // setting false so that after save, to not be prompted the modal when clicking back, but just redirecting to the contract partner list page
        queryClient.invalidateQueries(contractPartnerUrl);
        navigate("/contract-partners/list", { state });
      },
      onError: () => {
        notification.error({
          message: t("contractPartners.failedRequest"),
          description: t("contractPartners.failedUpdateRequestDescription"),
          placement: "topRight",
        });
      },
    },
  );

  const handleInitialValues = (data: ContractPartnerData): EditContractPartnerDataType => {
    return {
      ...data,
      purchaseSections: data.onboardedPurchaseSections?.map((item) => ({
        value: item.id === null ? "*" : item.id,
        label: item.name,
        countryId: item.countryId,
      })),
      supplier: {
        ...data.supplier,
        id: String(data.supplier.id),
      },
    };
  };

  if (isContractPartnerLoading) {
    return <Spin size="large" />;
  }

  if (!contractPartnerId || isContractPartnerError || !contractPartnerData) {
    if (contractPartnerError?.response?.status === 403) {
      navigate("/not-authorized");
    }

    return (
      <Result
        status="404"
        title="404"
        subTitle={t("contractPartners.404-description")}
        extra={
          <Button type="primary" onClick={() => navigate("/contract-partners/list", { state })}>
            {t("app.backHomeButton")}
          </Button>
        }
      />
    );
  }

  if (isContractPartnerSuccess) {
    const initialValues = handleInitialValues(contractPartnerData);
    return <Editor initialData={initialValues} isEditing={true} onSave={updateContractPartnerMutation} />;
  }
  return null;
}
